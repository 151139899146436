@import '../../../defaults';

// Elementor
.list-group-item {
  --anw-list-group-item-padding-x: 0;
  --anw-list-group-item-padding-y: 0.2rem;
  box-sizing: border-box;
  .badge {
    font-size: 0.75rem;
    position: relative;
    top: -0.15rem;
    width: 1.15rem;
  }
}

.elementor {
  h2 {
    color: $info;
    font-family: $headings-font-family;
    font-size: $h4-font-size;
    font-weight: $font-weight-normal;
    line-height: $headings-line-height;
    margin-bottom: $headings-margin-bottom;
  }

  h5 {
    font-size: $anw-custom-h2-font-size;
  }

  ol {
    margin-left: map-get($spacers, 2);
  }

  ul {
    margin-left: map-get($spacers, 1);
  }

  ol li::before {
    color: $dark;
    font-weight: $anw-fontawesome-font-weight-solid;
    content: counter(li) '. ';
    direction: ltr;
  }

  .alert {
    i {
      margin: 0 15px 0 0;
      font-size: 36px;
    }
  }

  div[class*='anw-secondary'] {
    border-width: 0;
    background-color: $gray-300;

    .anw-alert-content {
      color: $body-color;
    }
  }
}

.elementor-widget-ANWImageText,
.elementor-col-50,
.elementor-widget-html,
.elementor-widget-image,
.elementor-widget-video,
.elementor-text-editor {
  margin-bottom: map-get($spacers, 5);
}

.elementor-aspect-ratio-169 .elementor-fit-aspect-ratio {
  height: 0;
  padding-bottom: 56.5%;
  position: relative;
}

.elementor-widget-video {
  padding-top: map-get($spacers, 3);
}

.elementor-video-iframe {
  border: 0;
  height: 100%;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.wp-anw-table {
  overflow-y: auto;

  .table {
    table-layout: fixed;

    th,
    td {
      hyphens: auto;
      word-wrap: break-word;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
    }
  }
}

.elementor-widget-video {
  .elementor-widget-container div {
    font-size: 0.8rem;
  }
}

.elementor-widget-image {
  img,
  figure img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    font-size: 0.8rem;
  }
}

.anw-teaser-box {
  background-color: $gray-300;
  padding: 11px 12px 20px;

  .elementor-image-box-wrapper {
    display: flex;
  }

  .elementor-image-box-img {
    padding-right: 12px;
    padding-top: 9px;
    width: 40%;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .elementor-image-box-content {
    width: 60%;

    p {
      display: none;
      margin-right: 0.35em;
    }
  }

  h3 {
    color: $info;
    font-weight: $font-weight-bold;
  }
}

.anw-readmore-box {
  margin-bottom: map-get($spacers, 3);
  margin-top: map-get($spacers, 3);

  .anw-link-heading {
    display: block;
    padding-bottom: map-get($spacers, 3);
  }
}

.elementor-widget-ANWReadmore::before {
  color: $gray-600;
  display: block;
  font-family: $headings-font-family;
  font-size: $h3-font-size;
  font-weight: $font-weight-light;
  line-height: $headings-line-height;
}

@include media-breakpoint-up(md) {
  .anw-teaser-box .elementor-image-box-content p {
    display: inline;
  }
}

@include media-breakpoint-up(lg) {
  .elementor h2,
  .elementor-widget-ANWReadmore::before {
    font-size: 1.625rem;
  }
}

@include media-breakpoint-up(xl) {
  .elementor-inner-section {
    display: table;

    .elementor-col-50 {
      display: table-cell;
      width: calc(50%);

      &:first-child {
        h2 {
          margin-top: -25px;
          padding-top: 30px;
        }

        border-right: 1px solid $gray-300;
        padding-right: 20px;
      }

      &:last-child {
        padding-left: 20px;
      }
    }
  }
}

.rounded-lg {
  border-radius: 10px !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-auto {
  margin-right: auto;
}
